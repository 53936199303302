import React from "react";
import Asideblack from "./components/Asideblack";
import Asidewhite from "./components/Asidewhite";
import Title from "./components/Title";
function App() {
    return (
        <>
            <Asideblack />
            <Asidewhite />
            <Title />
        </>
    );
}

export default App;